<template>
  <div>
    <vs-card>
      <div slot="header">
        <vs-row>
          <vs-col vs-w="11"><h4>Whatsapp Shootout</h4></vs-col>
          <vs-col vs-w="1">
            <vs-button
              radius
              color="success"
              style=""
              type="gradient"
              icon="add"
              @click="whatsappAddpopup()"
            ></vs-button>
          </vs-col>
        </vs-row>
      </div>
      <div>
        <template lang="html">
          <div>
            <vs-table :data="users">
              <template slot="header"> </template>
              <template slot="thead">
                <vs-th>
                  Name
                </vs-th>
                <vs-th>
                  Message
                </vs-th>
                <vs-th>
                  Sender Name
                </vs-th>
                <vs-th>
                  Time
                </vs-th>
                <vs-th>
                  Active Status
                </vs-th>
                <vs-th class="heh">
                  Action
                </vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].id">
                    {{ data[indextr].name }}
                  </vs-td>

                  <vs-td :data="data[indextr].id">
                    {{ data[indextr].message }}
                  </vs-td>

                  <vs-td :data="data[indextr].id">
                    {{ data[indextr].added_by_name }}
                  </vs-td>

                  <vs-td :data="data[indextr].id">
                    {{ data[indextr].created_at }}
                  </vs-td>
                  <vs-td :data="data[indextr].id">
                    <!-- <vs-icon size="small" icon="textsms" color="success"></vs-icon> -->
                    {{ data[indextr].status }}
                  </vs-td>
                  <vs-td :data="data[indextr].id">
                    <vs-row>
                      <vs-col
                        vs-w="12"
                        style="display: flex;justify-content: center;"
                      >
                      <span style="margin:5px;">
                          <!-- <vs-button
                            v-if="data[indextr].status === 'Inactive'"
                              size="small"
                              color="success"
                              type="gradient"
                              icon="done"
                              @click="openActivatePopup(tr)"
                              >Activate</vs-button
                            > -->
                            <vs-button
                              v-if="data[indextr].status === 'Active'"
                              size="small"
                              color="danger"
                              type="gradient"
                              icon="block"
                              @click="openDeactivatePopup(tr)"
                              >Deactivate</vs-button
                            >
                        </span>
                        <span style="margin:5px;">
                          <vs-button
                            radius
                            color="warning"
                            style=""
                            type="gradient"
                            icon="edit"
                            @click="whatsappEditpopup(tr)"
                          ></vs-button>
                        </span>
                        <span style="margin:5px;">
                          <vs-button
                            radius
                            color="danger"
                            style=""
                            type="gradient"
                            icon="delete"
                            @click="whatsappDeletepopup(tr.id)"
                          ></vs-button></span
                      >
                    </vs-col>
                    </vs-row>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </template>
      </div>
    </vs-card>
    <vs-row>
      <vs-col vs-justify="flex-end" vs-w="12">
        <vs-pagination :total="total_pages" v-model="current_page"></vs-pagination>
      </vs-col>
    </vs-row>
    <vs-popup class="holamundo"  title="Deactivate Broadcast" :active.sync="open_deactivate_popup">
        <vs-row>
          <!-- <vs-col vs-type="flex" vs-justify="right" vs-align="rirght" vs-w='3'><b>Name</b> </vs-col><vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w='8'> {{popup_data.name}}</vs-col> -->
          <vs-col style="margin-top:1%" vs-type="flex" vs-justify="center" vs-align="center"><b>Are you sure, Do you want to Deactivate this({{ popup_data.name }}) broadcast?</b></vs-col>
        </vs-row>
        <vs-row>
          <vs-col style="margin-top: 4%" vs-type="flex" vs-justify="center" vs-align="center">
            <vs-button color="danger" type="filled" @click="changeActiveStatus(0, popup_data)">Yes</vs-button>
            <vs-button style="margin-left: 1%;" color="warning" type="filled" @click="open_deactivate_popup=false">No</vs-button>
          </vs-col>
        </vs-row>
    </vs-popup>
    <vs-popup class="holamundo"  title="Activate Broadcast" :active.sync="open_activate_popup">
      <vs-row>
        <vs-col style="margin-top:1%" vs-type="flex" vs-justify="center" vs-align="center"><b>Are you sure, Do you want to Activate this({{ popup_data.name }}) broadcast?</b></vs-col>
      </vs-row>
      <vs-row>
        <vs-col style="margin-top: 4%" vs-type="flex" vs-justify="center" vs-align="center">
          <vs-button color="danger" type="filled" @click="changeActiveStatus(0, popup_data)">Yes</vs-button>
          <vs-button style="margin-left: 1%;" color="warning" type="filled" @click="open_activate_popup=false">No</vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
    <vs-popup class="holamundo" title="Add" :active.sync="addpopup">
      <div class="centerx ">
        <vs-row>
          <vs-col
            vs-w="2"
            style="    display: flex;justify-content: center; margin-top: 5px;"
          >
            Name :
          </vs-col>
          <vs-col vs-w="10" style="    display: flex;justify-content: center;">
            <vs-input
              class="inputx"
              placeholder="Please Enter Name"
              v-model="addToName"
            />
          </vs-col> </vs-row
        ><br />
        <vs-row>
          <vs-col vs-w="13" style="    display: flex;justify-content: center;">
            <vs-textarea
              placeholder="enter message"
              height="200px"
              v-model="addMessage"
            />
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-w="13" style="    display: flex;justify-content: center;">
            <vs-button
              color="success"
              size="small"
              @click="whatsappAdd()"
              type="filled"
              >Send</vs-button
            >
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
    <vs-popup class="holamundo" title="Edit" :active.sync="editpopup">
      <div class="centerx ">
        <vs-row>
          <vs-col
            vs-w="2"
            style="    display: flex;justify-content: center; margin-top: 5px;"
          >
            Name :
          </vs-col>
          <vs-col vs-w="10" style="    display: flex;justify-content: center;">
            <vs-input
              class="inputx"
              placeholder="Placeholder"
              v-model="editToName"
            />
          </vs-col> </vs-row
        ><br />
        <vs-row>
          <vs-col vs-w="13" style="    display: flex;justify-content: center;">
            <vs-textarea
              placeholder="enter message"
              height="200px"
              v-model="editMessage"
            />
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-w="13" style="    display: flex;justify-content: center;">
            <vs-button
              color="success"
              size="small"
              @click="whatsappEdit()"
              type="filled"
              >Save</vs-button
            >
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import constants from "../../constants.json";

import axios from "axios";
import moment from "moment";
export default {
  data: () => ({
    users: [],
    editpopup: false,
    addpopup: false,
    editWhatsappName: "",
    editID: null,
    addToName: "",
    addMessage: "",
    editMessage: "",
    editToName: "",
    popup_data: {},
    open_deactivate_popup: false,
    open_activate_popup: false,
    total_pages: 1,
    current_page: 1,
  }),
  components: {
    moment,
  },
  mounted() {
    this.getWhatsappData();
  },
  watch: {
    current_page() {
      this.getWhatsappData();
    },
  },
  methods: {
    openDeactivatePopup(broadcast_data){
      this.popup_data = {};
      this.popup_data = broadcast_data;
      this.open_deactivate_popup = true;
    },
    openActivatePopup(broadcast_data){
      this.popup_data = {};
      this.popup_data = broadcast_data;
      this.open_activate_popup = true;
    },
    changeActiveStatus(status, data) {
      console.log("changeActiveStatus",status);
      console.log('msg_data', data)
      this.$vs.loading();
      let obj = {
        whatsapp_message_id: data.id
      };
      let url = `${constants.MILES_WHATSAPP_BROADCASTS}rejectBroadcstForAll`;
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log('rejectBroadcstForAll', response.data);
          this.getWhatsappData();
          if (response.data.message == 'Not authorised to reject') {
            this.$vs.notify({
            title: response.data.message,
            text: "",
            color: "danger",
          });
          }else{
          this.$vs.notify({
            title: "Deactivated successfully",
            text: "",
            color: "success",
          });
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
      this.open_deactivate_popup = false;
      // this.open_activate_popup = false;
    },
    getWhatsappData() {
      this.$vs.loading();
      let url = `${constants.SERVER_API}WhatsappBroadcastMessage?page=`+ this.current_page;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getWhatsappData", response);
          this.total_pages = response.data.last_page;
          this.current_page = response.data.current_page;
          this.users = response.data.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
    },
    whatsappEditpopup(tr) {
      this.editToName = tr.name;
      this.editMessage = tr.message;
      this.editID = tr.id;
      this.editpopup = true;
    },
    whatsappEdit() {
      let obj = {
        name: this.editToName,
        message: this.editMessage,
      };
      let url = `${constants.SERVER_API}WhatsappBroadcastMessage/${this.editID}`;
      axios
        .put(
          url,
          obj,
          {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.getWhatsappData();
          if (response.data == 'Not Authorised to Edit whatsAppBroadCastMessage') {
            this.$vs.notify({
            title: response.data,
            text: "",
            color: "danger",
          });
          }else{
          this.$vs.notify({
            title: "Successfully Updated",
            text: "",
            color: "success",
          });
          }
          this.editpopup = false;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    whatsappAdd() {
      let obj = {
        name: this.addToName,
        message: this.addMessage,
      };
      let url = `${constants.SERVER_API}WhatsappBroadcastMessage`;
      axios
        .post(
          url,
          obj,
          {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.getWhatsappData();
          if (response.data == 'Not Authorised to Create whatsAppBroadCastMessage') {
            this.$vs.notify({
            title: response.data,
            text: "",
            color: "danger",
          });
          }else{
          this.$vs.notify({
            title: "Successfully Created",
            text: "",
            color: "success",
          });
          }
          this.addpopup = false;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    whatsappDeletepopup(id) {
      let url = `${constants.SERVER_API}WhatsappBroadcastMessage/${id}`;
      axios
        .delete(
          url,
          {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.getWhatsappData();
          if (response.data == 'Not Authorised to Delete whatsAppBroadCastMessage') {
            this.$vs.notify({
            title: response.data,
            text: "",
            color: "danger",
          });
          }else{
          this.$vs.notify({
            title: "Successfully Deleted",
            text: "",
            color: "success",
          });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
      console.log("delete id", id);
    },
    whatsappAddpopup() {
      this.addpopup = true;
    },
  },
};
</script>
<style>
.heh div.vs-table-text {
  justify-content: center;
}
</style>
